import React from "react"
import { actions as processActions } from "modules/process"
import { css } from "styled-components"
import theme from "styles/theme-main"
import styled from "styled-components"
import {
  getFormConfigurationFromRole,
  getFormConfigurationFromCandidate,
  getFormConfigurationFromOrganization,
} from "utils/configUtils"
import YesNoIcon from "components/YesNoIcon"
import {
  STAGES,
  CODESTAGES,
  LABELS,
  UNIVERSITY_GROUPS,
  STEPS,
  CANDIDATE_STATUS,
  LABELSFUNC,
  USERTYPES,
  SESSION_DURATION,
  REJECTED_GROUP_STAGE,
  UK_COUNTRIES,
  DEGREE_TYPE,
  INTERVIEW_STAGES,
  OFFER_MADE_GROUP_STAGE,
  generateOption,
  VALUABLE_RESOURCES,
  VALUABLE_RESOURCES_NON_LAW,
  MIXED_OTHER_ETHNICITY_IDS,
  MIXED_OTHER_ETHNICITY_ID,
  ETHNICITY_OTHER_ID,
  CUSTOM_STRING_SPLITTER,
  VANTAGE_FIND,
  getAssessmentTypes,
  UK_COUNTRY_OTHER_ID_THAT_USES_API,
  OZ_DEFAULT_COUNTRY,
  UK_DEFAULT_COUNTRY,
  UNDERGRADUATE,
  ASSESSMENT_LINK_PLACEHOLDER,
  LINK_PLACEHOLDER,
  WESTERN_AUSTRALIA_COUNTRY_ID,
  QUALIFICATION_OTHER_ID,
  ATAR_EXAMINATION,
  TERTIARY_EDUCATION,
  UK_COUNTRY_OTHER_ID,
  OZ_COUNTRY_OTHER_ID,
  ATS,
} from "./constants"
import { isStaging, isProd } from "utils/config"
import { format } from "date-fns"
import CookieHelper from "./cookie"
import {
  isNonLegalClient,
  formatRoleType,
  isLegalClient,
  isOZAPI,
} from "utils/clientUtils"
import { isAgencyApplication } from "utils/rolesUtils"
import {
  isProfileMode,
  isVantageMode,
  isCandidXMode,
  isApplicationMode,
  isHRPath,
  isLateralMode,
  isInternalMode,
  isExternalMode,
  isInternalAppsMode,
  getSubdomainFromUrl,
  isCRSAOMode,
} from "./productUtils"

const exclude = [
  ".",
  "?",
  "!",
  ",",
  ":",
  ";",
  "-",
  "_",
  "(",
  ")",
  "[",
  `'`,
  '"',
  "~",
  "{",
  "}",
]

const sizes = theme.mobileBreakpoints

const FeedbackWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const FeedbackContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;
  min-width: 39px;

  &:last-of-type {
    margin-right: 0;
  }
`

const FeedbackCount = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.textPrimary};
  font-weight: 400;
  margin-right: 4px;
`

const FeedbackIcon = styled.div`
  width: 22px;
  height: 22px;
  color: #4a5b68;
`

/**
 * Helper function to handle error/success/loading messages in the redux process module
 * @param processName
 * @param processType
 * @param msg
 * @returns {*}
 */
export const emitProcess = (processName, processType, msg) => {
  switch (processName) {
    case "loading":
      return processActions.processLoading({
        type: processType,
        message: msg,
      })
    case "success":
      return processActions.processSuccess({
        type: processType,
        message: msg,
      })
    case "error":
      return processActions.processFailure({
        type: processType,
        message: msg,
      })
    default:
      return processActions.processLoading({
        type: processType,
        message: msg,
      })
  }
}

export function getAppPathForOrganization(subdomain) {
  return `${subdomain}/`
}

/**
 * Returns the organization name based on the current location pathname
 * @returns {any}
 */

export function generateRandomString() {
  let result = ""
  const Uppercharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const lowerCharacters = "abcdefghijklmnopqrstuvwxyz"
  const numbers = "0123456789"
  const special = "A!#$%@%£%"
  const types = [Uppercharacters, lowerCharacters, numbers, special]
  const charactersLength = 5
  types.forEach(type => {
    for (var i = 0; i < charactersLength; i++) {
      result += type.charAt(Math.floor(Math.random() * type.length))
    }
  })
  return result
}

/**
 * Returns more readable sort label for GE events tracking
 * @param sortLabel
 * @returns {string}
 */
export function getGAsortLabel(sortLabel) {
  const GAfieldLabel = {
    FLAGS: "Flags",
    KS5_GRADES: "KS5 grades",
    KS5_SCHOOL_PI: "KS5 PI",
    UNIVERSITY_PI: "University PI",
    DATE_UPDATED: "Date updated",
  }
  return GAfieldLabel[sortLabel]
}

/**
 * Triggers a Crazy Egg 'converted' method.
 * Requires a 'trigger code' that matches the AB test code set
 * up in the CE AB test dashboard.
 * @param triggerCode
 * @returns {boolean}
 */
export function crazyEggABTrigger(triggerCode) {
  // eslint-disable-next-line no-undef
  CE2.converted(triggerCode)
  return true
}

/**
 * Check if arrays are equal
 * @param _arr1
 * @param _arr2
 * @returns {boolean}
 */
export function arraysEqual(_arr1, _arr2) {
  if (!_arr1 || !_arr2 || _arr1.length !== _arr2.length) return false

  let arr1 = _arr1.concat().sort()
  let arr2 = _arr2.concat().sort()

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}

/**
 * Capitilize first char and make the rest lower case
 * @param string
 * @returns {string}
 */
export function capitalizeFirstLetter(string) {
  if (string && typeof string === "boolean") {
    return string ? "Yes" : "No"
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function isAdmin(user) {
  return user && user.id && user.userType === "ADMIN"
}

export function isHiringManagerAdmin(user) {
  return (
    user &&
    user.id &&
    (user.userType === "HIRING_MANAGER_ADMIN" || isAdmin(user))
  )
}

export function isHiringManager(user) {
  return (
    user &&
    user.id &&
    (user.userType === "HIRING_MANAGER" || isHiringManagerAdmin(user))
  )
}

export const isVantagePassportCandidate = candidateStatus =>
  candidateStatus === "PASSPORT_ACTIVE"

/**
 * Scrolls to the first element of the given class.
 * Commonly used when a form is submitted and you need to scroll to the first element that has an error
 * @param className
 * @param offset
 */
export function scrollToFirstElementOfClass(className, offset = 65) {
  let element = document.querySelector(className)

  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY
    window.scroll({
      top: y - offset,
      behavior: "smooth",
    })
  }
}

/**
 * Scroll to top
 * @param className
 * @param offset
 */
export function scrollToTop(className, offset = 65) {
  window.scroll({
    top: 0 - offset,
    behavior: "smooth",
  })
}

/**
 * Gets the url parameter my name
 * @param name
 * @param url
 * @returns {*}
 */
export function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, "\\$&")
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

/**
 * Console log only in non production mode
 * @param msg
 */
export const logToConsole = msg => {
  if (process.env.REACT_APP_API_ENV !== "production") {
    console.log(msg)
  }
}

// styled components utils

/**
 * truncates the text and adds an ellipsis at the end
 * @param width
 * @returns {string}
 */
export function truncate(width) {
  return `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
}

/**
 * Gets the font size and the lineheight based on the size label
 * @param type
 * @returns {string}
 */
export function fontSize(type) {
  return `
    font-size: ${theme.typography.sizes[type]};
    line-height: ${theme.typography.lineHeights[type]};
    color: ${theme.typography.colors[type]};
  `
}

/**
 * Prints the mobile
 * @param type
 * @param breakPointLabel
 * @returns {string}
 */
export function mobileFontSize(type, breakPointLabel) {
  return `
      font-size: ${theme.typography.mobileSizes[breakPointLabel][type]};
      line-height: ${theme.typography.mobileLineHeights[breakPointLabel][type]};
  `
}

/**
 * Helper function that assists with responsive design
 * @type {{}}
 */
// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const pxSize = sizes[label]
  accumulator[label] = (...args) => css`
    @media (max-width: ${pxSize}px) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

/**
 * Gets the current viewport size (xs, sm, md, lg etc)
 * @returns {string}
 */
export function getCurrentSize() {
  let d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0]

  const windowWidth = window.innerWidth || e.clientWidth || g.clientWidth

  for (let i in sizes) {
    if (windowWidth <= sizes[i]) return i
  }
  return "xl"
}

/**
 * Checks if string is email
 * @param val
 * @returns {boolean}
 */
export function isEmail(val) {
  if (!val) return false
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(val)
}

/**
 * Checks if string is password with at least 9 characters, one uppercase, one number and one special char
 * @param val
 * @returns {boolean}
 */
export function isPassword(val) {
  if (!val) return false
  const re = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).*$/
  return re.test(val) && containsUppercase(val) && hasLowerCase(val)
}

function containsUppercase(str) {
  return /[A-Z]/.test(str)
}

function hasLowerCase(str) {
  return /[a-z]/.test(str)
}

/**
 * Checks if the value starts with 07 that is followed by 9 digits
 * @param val
 * @returns {boolean}
 */
export function isUKPhone(val) {
  if (val.indexOf("+44") === 0) {
    val = "0" + val.substring(3)
  }

  if (!val) return false

  const regExp = /^07[0-9]{9}|306937473828/g

  if (val && val.length !== 11) {
    return false
  }

  return regExp.test(val)
}

/**
 * Prints a sign in front of a number
 */
export function printNumberWithSign(num) {
  if (num === 0) return "0"
  if (!num || isNaN(parseInt(num))) return ""

  if (num > 0) {
    return "+" + num
  } else {
    return num.toString()
  }
}

/**
 * Formats percentile values 1st, 2nd, 3rd etc
 * @param num
 * @returns {string}
 */
export function formatPercentile(num) {
  if (!num || isNaN(parseInt(num))) return " - "

  const str = parseInt(num)
    .toString()
    .slice(-1)

  if (num.toString().startsWith("1") && num.toString().length === 2) {
    return num + "th"
  } else if (parseInt(str) === 1) {
    return num + "st"
  } else if (parseInt(str) === 2) {
    return num + "nd"
  } else if (parseInt(str) === 3) {
    return num + "rd"
  } else {
    return num + "th"
  }
}

/**
 * Get domain from name
 * @param str
 * @param suffix
 * @returns {*}
 */
export function getDomainFromName(str, suffix) {
  if (!str) return `${suffix}`
  return `${str.toLowerCase().replace(/[^A-Z0-9]/gi, "")}.${suffix}`
}

/**
 * Gets the error text from a server error response
 * @param payload
 * @returns {*|string}
 */
export function getErrorMessage(payload) {
  let errorMsg = "Some error happened. Please try again later."
  let error

  if (payload && payload.message && typeof payload.message !== "string") {
    error =
      payload.message.response && payload.message.response.data
        ? payload.message.response.data
        : payload.message
  } else if (payload && payload.response && payload.response.data) {
    error = payload.response.data
  } else {
    error = payload
  }

  if (!error) return errorMsg

  if (error.errors && error.errors.length > 0) {
    errorMsg = `${error.errors[0].message}`
  } else if (typeof error === "string") {
    errorMsg = error
  } else if (error && error.error_description) {
    errorMsg = error.error_description
  } else {
    errorMsg =
      error && error.message
        ? error.message
        : "Some error happened. Please try again later."
  }
  return errorMsg
}

/**
 * Get home route based on user type
 * @param user
 * @returns {*}
 */

export const hasApplications = user => {
  return (
    user &&
    user.applications &&
    user.applications.filter(x => !!x.role).length > 0
  )
}

export const getHomeRoute = function(user, organization, useRedirect = false) {
  if (useRedirect) {
    const redirect = getRedirectURL()
    if (redirect) {
      return redirect
    }
  }
  let url
  switch (user.userType) {
    case "CANDIDATE":
    case "ANONYMOUS_CANDIDATE":
      url = hasApplications(user) ? "/applications" : "/profile/create"
      break
    case "HIRING_MANAGER_ADMIN":
    case "HIRING_MANAGER":
    case "ADMIN":
      url = isProfileMode() ? "/manager/search" : "/manager/roles"
      break
    default:
      return isVantageMode()
        ? window.location.pathname && window.location.pathname !== "/login"
          ? `/login?url=${window.location.pathname}&search=${window.location.search}`
          : "/signup"
        : `${isHRPath() || isCandidXMode() ? "/login" : "/roles"}?url=${
            window.location.pathname
          }&search=${window.location.search}`
  }
  return `${url}${window.location.search}`
}

export const isPreviewingApplication = location =>
  location && location.pathname && location.pathname.indexOf("/applyp") === 0

export const removeWhitespaceFromStart = str => {
  if (str == null) return str
  return str.replace(/^\s+/g, "")
}

/**
 * Get the filter label from the filter object
 * @param filter
 * @returns {*}
 */
export const getFilterLabel = (filter, ukAPI = true) => {
  if (!filter) return "false"

  let label = filter.value
  switch (filter.type) {
    case "deadlinePassed":
      return `Deadline passed`
    case "interviewDate":
      const displayDate = format(new Date(filter.value), "dd-MM-yyyy")
      return `Interview on ${displayDate}`
    case "openDate":
      label = ""
      if (filter.value[0]) {
        label += "From " + formatDateShort(new Date(filter.value[0]))
      }
      if (filter.value[1]) {
        label += " To " + formatDateShort(new Date(filter.value[1]))
      }
      break
    case "location":
      const isPostCodeValid = filter.isPostCodeValid
      const postCode =
        filter.postCode && isPostCodeValid
          ? filter.postCode
          : "Invalid post code"

      const miles = filter.miles || "0"
      label = miles ? postCode + ", Within " + miles + " miles" : postCode
      break
    case "secondarySchoolPercentile":
    case "ks4schoolType":
      return `KS4: ${filter.value}`
    case "assessmentScore":
      return `Assessment score: ${filter.value} or more`
    case "ks5schoolType":
      return `KS5: ${filter.value}`
    case "ks4SchoolPercentile":
      const values = filter.value
      label = `${LABELS[filter.type]}: ` + values[0] + " - " + values[1]
      break
    case "ks5SchoolPercentile":
      const values1 = filter.value
      label =
        `${LABELSFUNC(ukAPI)[filter.type]}: ` + values1[0] + " - " + values1[1]
      break
    case "university":
      const universities = filter.value
      const group = filter.group

      if (
        group &&
        group.id === "all" &&
        universities &&
        universities.length > 0
      ) {
        let uniNames = ""

        universities.map((i, index) => {
          if (index === 0) {
            uniNames += i.value
          } else {
            uniNames += " | " + i.value
          }
          return i
        })

        label = uniNames
      } else if (group && group.id) {
        label = `${UNIVERSITY_GROUPS[filter.group.id]}`
      }

      break
    case "groups":
      const groups = filter.value
      label =
        groups.length > 0
          ? `In ${groups.length > 1 ? "groups: " : "group: "} ${groups
              .map(({ value }) => value)
              .join(" | ")}`
          : ""
      break
    case "ethnicity":
      const ethnicities = filter.value
      const ethnicityGroup = filter.group

      if (
        ethnicityGroup.id === "ethnicityall" &&
        ethnicities &&
        ethnicities.length > 0
      ) {
        let eNames = ""

        ethnicities.map((i, index) => {
          if (index === 0) {
            eNames += i.shortName || i.value
          } else {
            eNames += " | " + (i.shortName || i.value)
          }
          return i
        })

        label = eNames
      } else if (
        ethnicityGroup &&
        ethnicityGroup.id &&
        ethnicityGroup.id !== "ethnicityall" &&
        ethnicities.length > 0
      ) {
        label = `${ethnicityGroup.label}`
      }

      break
    case "ks4Grade":
      if (filter.value && filter.value.grade && filter.value.amount) {
        const { grade, amount } = filter.value

        label =
          `${LABELS[filter.type]}: ` +
          grade.value +
          " or above, " +
          amount.value +
          " or more"
      } else {
        label = `${LABELS[filter.type]}: `
      }
      break
    case "ks5Grade":
    case "universityDegreeAverage":
    case "universityDegreePI":
    case "degreeClass":
    case "universityYear1Average":
    case "universityYear2Average":
    case "universityYear3Average":
    case "universityYear4Average":
    case "universityModuleContractScore":
    case "universityModulePublicScore":
    case "universityModuleTortScore":
    case "universityYear1PI":
    case "universityYear2PI":
    case "universityYear3PI":
    case "universityYear4PI":
    case "universityContractPI":
    case "universityPublicPI":
    case "universityTortPI":
      label = filter.value ? LABELS[filter.type] + ": " + filter.value : null
      break
    case "ks5SchoolPI":
      label = filter.value
        ? LABELSFUNC(ukAPI)[filter.type] + ": " + filter.value + "% or more"
        : null
      break
    case "ks4SchoolPI":
      label = filter.value
        ? LABELSFUNC(ukAPI)[filter.type] + ": " + filter.value + "% or more"
        : null
      break
    case "universityGraduationYear":
    case "universityStartYear":
    case "secondUniversityStartYear":
    case "secondUniversityEndYear":
    case "secondUniversityQualificaftionType":
    case "ks4CompletionYear":
      if (filter.value && filter.value.length > 1) {
        label =
          LABELS[filter.type] +
          ": " +
          filter.value[0] +
          " - " +
          filter.value[filter.value.length - 1]
      } else {
        label = LABELS[filter.type] + ": " + filter.value.toString()
      }
      break
    case "ks5CompletionYear":
      if (filter.value && filter.value.length > 1) {
        label =
          LABELSFUNC(ukAPI)[filter.type] +
          ": " +
          filter.value[0] +
          " - " +
          filter.value[filter.value.length - 1]
      } else {
        label = LABELSFUNC(ukAPI)[filter.type] + ": " + filter.value.toString()
      }
      break

    case "disability":
      label = "Disability"
      break
    case "orientation":
      label = "LGBTQ+"
      break
    case "hilo":
      label = "HiLo schools"
      break
    case "onestowatch":
      label = "Added to a group"
      break
    case "adjustmentRequired":
      label = "Adjustments required"
      break
    case "extenuating":
      label = "Extenuating circumstances"
      break
    case "previousApps":
      label = "Previous applications"
      break
    case "convictions":
      label = "Criminal convictions"
      break
    case "hasInternalContact":
      label = "Internal contacts"
      break
    default:
      label = filter.value
  }

  return label
}

/**
 * Cleans up empty values that may cause issues when sent to server
 * @param obj
 */
export const cleanUpEmptyValues = obj => {
  const newObj = {}

  for (let i in obj) {
    if (obj[i] === null || (obj[i] && obj[i].length === 0)) {
    } else {
      newObj[i] = obj[i]
    }
  }

  return newObj
}

export const shouldNotHideMenu = (configuration, key) => {
  return (
    !configuration || !configuration.menus || configuration.menus[key] !== false
  )
}

export const shouldNotHideItem = (configuration, key) => {
  return (
    !configuration ||
    !configuration.fields ||
    configuration.fields[key] !== false
  )
}

export const isInternalAddOn = configuration => {
  return isHREnabled(configuration, "internal")
}

export const isInternalAddOnFromRole = role => {
  return isHREnabled(getFormConfigurationFromRole(role), "internal")
}

export const isHREnabled = (configuration, key) => {
  return configuration && configuration.hr && configuration.hr[key] === true
}

export const isHRDisabled = (configuration, key) => {
  return configuration && configuration.hr && configuration.hr[key] === false
}

export const isHRNotDisabled = (configuration, key) => {
  return !configuration || !configuration.hr || configuration.hr[key] !== false
}

export const shouldShowItem = (configuration, key) => {
  return (
    configuration && configuration.fields && configuration.fields[key] === true
  )
}

export const shouldHideItem = (configuration, key) => {
  return configuration?.fields?.[key] === false
}

export const shouldNotHideSection = (configuration, key) => {
  return (
    !configuration ||
    !configuration.subsections ||
    configuration.subsections[key] !== false
  )
}

export const shouldShowSection = (configuration, key) => {
  return (
    configuration &&
    configuration.subsections &&
    configuration.subsections[key] === true
  )
}

export const isFieldRequired = (configuration, key, value) => {
  return (
    configuration &&
    configuration.required &&
    configuration.required[key] === true &&
    isEmpty(value)
  )
}

export const isFieldNotRequired = (configuration, key, value) => {
  return (
    (!configuration ||
      !configuration.required ||
      configuration.required[key] !== false) &&
    isEmpty(value)
  )
}

export const getVantageApplication = user =>
  user.applications && user.applications.find(x => !x.role)

export const isRequired = (configuration, key) => {
  return (
    configuration &&
    configuration.required &&
    configuration.required[key] === true
  )
}

export const isNotOptional = (configuration, key) => {
  return (
    !configuration ||
    !configuration.required ||
    configuration.required[key] !== false
  )
}

const isHTML = input => {
  return (
    input &&
    /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(
      ("" + input).replace(/\n/g, "").replace(/\r/g, "")
    )
  )
}

export const getAlternativeText = (configuration, key, defaultValue) => {
  const result =
    configuration && configuration.text && configuration.text[key] !== undefined
      ? configuration.text[key]
      : null
  if (result !== null && result !== undefined) {
    if (isHTML(result)) {
      return <div dangerouslySetInnerHTML={{ __html: result }} />
    } else {
      return result
    }
  } else {
    if (isHTML(defaultValue)) {
      return <div dangerouslySetInnerHTML={{ __html: defaultValue }} />
    } else {
      return defaultValue
    }
  }
}

export const showCRS = candidate => {
  return candidate.role
    ? candidate.acceptTermAndConditions || candidate.acceptRare !== false
    : candidate.acceptTermAndConditions
}

export const getUserType = x => {
  const type = USERTYPES.find(t => t.id === x)
  return type && type.label
}

export const getCandidateNameFromUser = user => {
  const result = []
  if (user.firstName) {
    result.push(user.firstName)
  }
  if (user.preferredName) {
    result.push(`(${user.preferredName})`)
  }
  if (user.lastName) {
    result.push(user.lastName)
  }
  if (user.email && user.email.indexOf("---ANON--") === -1) {
    if (result.length === 0) {
      result.push(user.email)
    } else {
      result.push(`(${user.email})`)
    }
  }
  return result.join(" ")
}

export const optedOut = candidate =>
  candidate.email && candidate.email.indexOf("REJECTED") > -1

export const getCandidateRow = (candidate, value) =>
  candidate.anonymised ? " - " : value

export const getCandidateName = (candidate, includePreferred = true) => {
  if (optedOut(candidate)) {
    return "Opted out"
  }
  if (candidate.anonymised) {
    return "Anonymised candidate"
  }
  const result = []
  if (candidate.firstName) {
    result.push(candidate.firstName)
  }
  if (includePreferred && candidate.preferredName) {
    result.push(`(${candidate.preferredName})`)
  }
  if (candidate.lastName) {
    result.push(candidate.lastName)
  }
  if (result.length === 0 && candidate.anonymousEmail) {
    result.push(candidate.anonymousEmail)
  }
  if (
    result.length === 0 &&
    candidate.email &&
    candidate.email.indexOf("---ANON--") === -1
  ) {
    result.push(candidate.email)
  }
  if (result.length === 0 && candidate.externalCandidateId) {
    result.push(candidate.externalCandidateId)
  }
  if (result.length === 0) {
    result.push(candidate.id)
  }
  return result.join(" ")
}

export const convertMUI = theme => ({
  typography: {
    fontFamily: theme.typography.fonts.regular,
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: theme.colors.secondary,
      main: theme.colors.secondary,
      dark: theme.colors.secondary,
    },
    secondary: {
      light: theme.colors.textSecondary,
      main: theme.colors.textSecondary,
      dark: theme.colors.textSecondary,
    },
    text: {
      primary: theme.colors.textPrimary,
      secondary: theme.colors.textSecondary,
      disabled: theme.colors.border,
    },
  },
})

export const generateArray = (from, to, order) => {
  const result = []
  for (let i = from; i < to; i++) {
    result.push({
      label: i,
      value: i,
    })
  }
  if (order === -1) {
    result.sort((x, y) => y.label - x.label)
  }
  return result
}

export const getApplicationId = candidate => {
  return `${format(new Date(candidate.appliedAt), "yyyy-MM-dd")}-${
    candidate.id
  }`
}

export const formatDateTime = input =>
  input
    ? format(new Date(parseFloat(input)), "dd/MM/yy, h:mmaaa")
    : DEFAULT_DASH

export const isIE = () => !!document.documentMode

export const loadScript = src => {
  var tag = document.createElement("script")
  tag.async = true
  tag.src = src
  document.getElementsByTagName("body")[0].appendChild(tag)
}

export const getQueryParameters = (location = window.location) => {
  const params = location ? location.search || location.hash || "" : ""
  const tokens = params.substring(1).split("&")
  const result = {}
  tokens.forEach(x => {
    const two = x.split("=")
    if (two.length > 1) {
      result[two[0]] = two.slice(1).join("=")
    }
  })
  return result
}

export const getRedirectURL = () => {
  if (window.location && window.location.search) {
    const query = getQueryParameters(window.location)
    if (query.url) {
      return {
        pathname: query.url,
        search: query.search,
      }
    }
  }
}

export const isATSOrganization = organization => {
  return isApplicationMode() && organization && organization.productType === ATS
}

export const isLateralOrATSOrganization = organization => {
  return isLateralMode() || isATSOrganization(organization)
}

export const isCRSAOOrganization = organization => {
  return (
    isApplicationMode() && organization && organization.productType === "CRSAO"
  )
}

export const isUKSchoolCountry = country => {
  return (
    country &&
    UK_COUNTRY_OTHER_ID_THAT_USES_API.indexOf(parseInt(country.id)) > -1
  )
}

export const hasQualificationType = country => {
  const isOtherUKCountry =
    country && parseInt(country.id) === UK_COUNTRY_OTHER_ID
  const isOtherOZCountry =
    country && parseInt(country.id) === OZ_COUNTRY_OTHER_ID

  if (isOtherOZCountry) return false
  if (isCRSAOMode() && isOtherUKCountry) return false
  if (isUKSchoolCountry(country) || isOtherUKCountry) return true

  return country && country.id !== OZ_COUNTRY_OTHER_ID
}

const QUALIFICATION_IB = "12"
const QUALIFICATION_OTHER = "17"
const QUALIFICATION_EPQ = "21"
const QUALIFICATION_BTEC = "22"
const QUALIFICATION_ACCESS_TO_HE = "23"
const QUALIFICATION_T_LEVEL = "24"

export const isIBQualification = qualificationType =>
  qualificationType?.id === QUALIFICATION_IB

export const isSixthOtherQualification = qualificationType => {
  return [
    QUALIFICATION_OTHER,
    QUALIFICATION_EPQ,
    QUALIFICATION_BTEC,
    QUALIFICATION_ACCESS_TO_HE,
    QUALIFICATION_T_LEVEL,
  ].includes(qualificationType?.id)
}

export const isTertiaryQualification = qualificationType =>
  qualificationType?.id === TERTIARY_EDUCATION

export const isAustraliaQualification = qualificationType =>
  qualificationType?.id >= 200

export const getOverrideQualifications = (
  organization,
  qualifications,
  country,
  year
) => {
  if (!isOZAPI(organization)) {
    return qualifications
  }
  if (country && country.id === WESTERN_AUSTRALIA_COUNTRY_ID) {
    if (year?.id > 2019) {
      return [
        {
          id: QUALIFICATION_OTHER_ID,
          value: "Other",
        },
      ]
    } else if (year?.id > 2015) {
      return [
        {
          id: 204,
          value: ATAR_EXAMINATION,
        },
        {
          id: QUALIFICATION_OTHER_ID,
          value: "Other",
        },
      ]
    }
  }
  qualifications.sort((a, b) => {
    const sortsScoreA = a.id === QUALIFICATION_OTHER_ID ? 1000 : parseInt(a.id)
    const sortsScoreB = b.id === QUALIFICATION_OTHER_ID ? 1000 : parseInt(b.id)
    return sortsScoreA < sortsScoreB ? -1 : 1
  })
  return qualifications
}

export const getQualificationTitle = qualificationType => {
  if (qualificationType && showQualificationType(qualificationType)) {
    switch (qualificationType.id) {
      case "200":
      case "201":
        return "Unit"
      default:
        return "Grade"
    }
  }
}

export const getQualificationScoreTitle = qualificationType => {
  if (qualificationType && showQualificationType(qualificationType)) {
    switch (qualificationType.id) {
      case "200":
      case "201":
        return "Mark"
      case "202":
        return "Study Score"
      case "203":
        return "No. credits"
      default:
        return "Course Score"
    }
  }
}

export const getQualificationScorePlaceholder = qualificationType => {
  return (
    getQualificationMinScore(qualificationType) +
    "-" +
    getQualificationMaxScore(qualificationType)
  )
}

export const getQualificationMinScore = qualificationType => {
  if (qualificationType) {
    switch (qualificationType.id) {
      case "203":
      case TERTIARY_EDUCATION:
        return 1
      default:
        return 0
    }
  }
}

export const getQualificationMaxScore = qualificationType => {
  if (qualificationType) {
    switch (qualificationType.id) {
      case "202":
        return 50
      case "203":
        return 8
      case TERTIARY_EDUCATION:
        return 25
      default:
        return 100
    }
  }
}

export const showQualificationType = qualificationType => {
  return (
    qualificationType &&
    (qualificationType.id === "200" || qualificationType.id === "201")
  )
}

export const isCandidateOnATS = user => isApplicationMode() && isCandidate(user)

export const getHomePageNotLoggedIn = () => {
  if (isHRPath() || isCandidXMode()) {
    return "/login"
  } else {
    return isProfileMode() ? "/signup" : "/roles"
  }
}

export const isValidDate = d => {
  if (d) {
    const dateObject = new Date(d)
    return dateObject instanceof Date && !isNaN(dateObject)
  }
}

export const wordCount = input => {
  if (input) {
    const y = new RegExp(exclude.map(x => `[${x}]*`).join(""), "g")
    const cleanup = ("" + input)
      .trim()
      .replace(/\n/g, " ")
      .replace(/\]/g, "")
      .replace(/[ ]+/g, " ")
      .replace(y, "")
      .replace(/\r/g, " ")
      .replace(/^[ ]*/g, "")
    return cleanup.split(" ").length
  }
  return 0
}

export const convertBoolean = input => {
  return input !== null ? (input ? "Yes" : "No") : "-"
}

export const addPlusBreak = input => {
  return input ? (
    <React.Fragment>
      {input}
      <br />
    </React.Fragment>
  ) : (
    ""
  )
}

export const convertToText = input => {
  switch (input) {
    case 1:
      return "one"
    case 2:
      return "two"
    case 3:
      return "three"
    case 4:
      return "four"
    case 5:
      return "five"
    case 6:
      return "six"
    case 7:
      return "seven"
    case 8:
      return "eight"
    case 9:
      return "nine"
    case 10:
      return "ten"
    default:
      return input
  }
}

export const hasExperienceData = (organization, candidate) => {
  const formConfiguration = getFormConfigurationFromCandidate(candidate)
  const showApplications = showATSOnlySectionForOrganization(
    organization,
    formConfiguration,
    "applications"
  )
  const showExperience =
    isCandidXMode() ||
    isLateralMode() ||
    isATSOrganization(organization) ||
    (formConfiguration &&
      formConfiguration.custom &&
      formConfiguration.custom.work)

  return showApplications || showExperience
}

export const hasPersonalData = candidate => {
  const formConfiguration = getFormConfigurationFromCandidate(candidate)
  return (
    shouldShowItem(formConfiguration, "ukResident") ||
    shouldShowSection(formConfiguration, "citizenship") ||
    shouldShowItem(formConfiguration, "workVisaRequired") ||
    (shouldNotHideMenu(formConfiguration, "personal") &&
      shouldNotHideSection(formConfiguration, "residency"))
  )
}

export const DELIMITER = "___"

export const ARRAYDELIMITER = ">>><<<"

export const validatePassport = (configuration, props, section) => {
  const isCandidPlusCandidate = props.user && props.user.passportEnabled
  const showVantagePassport =
    !isInternalAppsMode() &&
    !isCandidPlusCandidate &&
    isLegalClient(props.organization)
  const showOptIn =
    configuration.tc &&
    configuration.tc.passport &&
    configuration.tc.passport.section === section
  return (
    (showVantagePassport && !props.passportOptIn) ||
    (showOptIn && !props.optInRare)
  )
}

export const validateTC = (configuration, props, section, apexMode) => {
  const {
    acceptResearch,
    acceptRare,
    acceptNotAIAssisted,
    optInRare,
    optInData,
    optOutRare,
    optInClient,
    writtenConsent,
    organization,
  } = props
  let writteConsentError
  let clientPrivacyError
  const oconfiguration = getFormConfigurationFromOrganization(organization)
  if (isLateralMode() && section === "step6") {
    writteConsentError = isAgencyApplication(props) && !writtenConsent
    clientPrivacyError =
      oconfiguration.text && !!oconfiguration.text.privacyLink && !optInClient
  }
  if (isProfileMode()) {
    if (section === "step6") {
      return (
        Boolean(!props.acceptTermAndConditions) || Boolean(!props.optInData)
      )
    } else {
      return false
    }
  } else if (apexMode && section === "crs") {
    const notAcceptedResearch =
      isBooleanTrue(acceptRare) &&
      (acceptResearch === null || acceptResearch === undefined)
    const notAcceptedRare =
      shouldNotHideSection(configuration, "CRSQuestions") &&
      (acceptRare === null || acceptRare === undefined)
    return notAcceptedResearch || notAcceptedRare
  }
  if (configuration.tc) {
    const notAcceptedResearch =
      (isBooleanTrue(acceptRare) ||
        (configuration.tc.accept && configuration.tc.accept.rareOptOut)) &&
      optOutRare !== true &&
      configuration.tc.accept &&
      section === configuration.tc.accept.section &&
      (acceptResearch === null || acceptResearch === undefined) &&
      configuration.tc.accept.acceptResearch !== false
    const notAcceptedRare =
      configuration.tc.accept &&
      section === configuration.tc.accept.section &&
      (acceptRare === null || acceptRare === undefined) &&
      configuration.tc.accept &&
      configuration.tc.accept.rareOptOut !== true
    const notOptedIn =
      configuration.tc.terms &&
      section === configuration.tc.terms.section &&
      configuration.tc.terms &&
      (optInRare === null || optInRare === false || optInRare === undefined)
    const notOptedInData =
      configuration.tc.data &&
      section === configuration.tc.data.section &&
      configuration.tc.data &&
      (optInData === null || optInData === false || optInData === undefined)
    const notAcceptedAIAssistance =
      section === "step6" &&
      isATSOrganization(organization) &&
      shouldShowItem(configuration, "aiAssistance") &&
      isBooleanFalseOrNull(acceptNotAIAssisted)
    return (
      notAcceptedResearch ||
      notAcceptedRare ||
      notOptedIn ||
      notOptedInData ||
      writteConsentError ||
      clientPrivacyError ||
      notAcceptedAIAssistance
    )
  }
  return false
}

const getBooleanFlat = (selectedFilters, keys) => {
  return selectedFilters.filter(i => i.type === keys).length > 0
    ? true
    : undefined
}

/**
 * Creates the search resource to be sent to the server
 * @param selectedFilters
 */
export const createSearchResource = (
  selectedFiltersUnfiltered,
  options = {}
) => {
  const selectedFilters = selectedFiltersUnfiltered.filter(Boolean)

  const query = JSON.stringify(selectedFilters.map(getFilterLabel))

  const ethnicity = selectedFilters.filter(i => i.type === "ethnicity")
  const socialMobilityNumOfFlags = selectedFilters.filter(
    i => i.type === "socialMobilityNumOfFlags"
  )
  const socialMobilityMetrics = selectedFilters.filter(
    i => i.type === "socialMobilityMetrics"
  )

  const interviewDate = selectedFilters.filter(i => i.type === "interviewDate")

  const deadlinePassed = selectedFilters.filter(
    i => i.type === "deadlinePassed"
  )

  const customQuestions = selectedFilters
    .filter(it => it.type === "customQuestion")
    .map(({ id: answer, question }) => {
      return {
        fullResponse: answer,
        questionId: question.id,
      }
    })

  const assignee = selectedFilters.find(i => i.type === "assignee")
  const gender = selectedFilters.filter(i => i.type === "gender")
  const statuses = selectedFilters
    .filter(i => i.type === "statuses")
    .map(x => x.id)

  const incompleteProfile = selectedFilters
    .filter(i => i.type === "incompleteProfile")
    .map(x => x.id)

  const healthConditions = selectedFilters
    .filter(it => it.type === "healthConditions")
    .map(it => it.id)

  const steps = selectedFilters.filter(i => i.type === "steps").map(x => x.id)
  const dateAdded = selectedFilters.filter(i => i.type === "dateAdded")
  const legalInterests = selectedFilters.filter(
    i => i.type === "legalInterests"
  )

  const location = selectedFilters.filter(i => i.type === "location")
  const universityDegreeAverage = selectedFilters.filter(
    i => i.type === "universityDegreeAverage"
  )
  const degreeClass = selectedFilters.filter(i => i.type === "degreeClass")
  const degreeSubject = selectedFilters.filter(i => i.type === "degreeSubject")
  const degreeType = selectedFilters.filter(i => i.type === "degreeType")

  const universityYear1Average = selectedFilters.filter(
    i => i.type === "universityYear1Average"
  )
  const universityYear2Average = selectedFilters.filter(
    i => i.type === "universityYear2Average"
  )
  const universityYear3Average = selectedFilters.filter(
    i => i.type === "universityYear3Average"
  )
  const universityYear4Average = selectedFilters.filter(
    i => i.type === "universityYear4Average"
  )
  const universityModuleContractScore = selectedFilters.filter(
    i => i.type === "universityModuleContractScore"
  )
  const universityModulePublicScore = selectedFilters.filter(
    i => i.type === "universityModulePublicScore"
  )
  const universityModuleTortScore = selectedFilters.filter(
    i => i.type === "universityModuleTortScore"
  )
  const universityGraduationYear = selectedFilters.filter(
    i => i.type === "universityGraduationYear"
  )
  const universityStartYear = selectedFilters.filter(
    i => i.type === "universityStartYear"
  )

  const universityDegreePI = selectedFilters.filter(
    i => i.type === "universityDegreePI"
  )

  const universityYear1PI = selectedFilters.filter(
    i => i.type === "universityYear1PI"
  )
  const universityYear2PI = selectedFilters.filter(
    i => i.type === "universityYear2PI"
  )
  const universityYear3PI = selectedFilters.filter(
    i => i.type === "universityYear3PI"
  )
  const universityYear4PI = selectedFilters.filter(
    i => i.type === "universityYear4PI"
  )
  const universityContractPI = selectedFilters.filter(
    i => i.type === "universityContractPI"
  )
  const universityPublicPI = selectedFilters.filter(
    i => i.type === "universityPublicPI"
  )
  const universityTortPI = selectedFilters.filter(
    i => i.type === "universityTortPI"
  )

  const ks4SchoolPercentile = selectedFilters.filter(
    i => i.type === "ks4SchoolPercentile"
  )

  const ks5SchoolPercentile = selectedFilters.filter(
    i => i.type === "ks5SchoolPercentile"
  )

  const assessmentScore = selectedFilters.filter(
    i => i.type === "assessmentScore"
  )

  const groups = selectedFilters.filter(i => i.type === "groups")

  const sources = selectedFilters
    .filter(i => i.type === "sources")
    .map(x => x.id)

  const permitTypes = selectedFilters
    .filter(i => i.type === "permitType")
    .map(x => x.id)

  const openDate = selectedFilters.find(i => i.type === "openDate")
  const wamRanges = selectedFilters
    .filter(i => i.type === "wamRanges")
    .map(x => x.id)
  const roleStartDate =
    openDate && openDate.value ? new Date(openDate.value[0]).getTime() : null
  const roleEndDate =
    openDate && openDate.value ? new Date(openDate.value[1]).getTime() : null

  const ks5SchoolPI = selectedFilters.filter(i => i.type === "ks5SchoolPI")
  const ks4SchoolPI = selectedFilters.filter(i => i.type === "ks4SchoolPI")

  const ks4CompletionYear = selectedFilters.filter(
    i => i.type === "ks4CompletionYear"
  )
  const ks5CompletionYear = selectedFilters.filter(
    i => i.type === "ks5CompletionYear"
  )
  const searchTerm = options.searchTerm
  const ks4Grade = selectedFilters.filter(i => i.type === "ks4Grade")
  const ks5Grade = selectedFilters.filter(i => i.type === "ks5Grade")
  const university = selectedFilters.filter(i => i.type === "university")

  const ks4schoolType = selectedFilters
    .filter(i => i.type === "ks4schoolType")
    .map(x => x.id)
  const ks5schoolType = selectedFilters
    .filter(i => i.type === "ks5schoolType")
    .map(x => x.id)

  const hilo = getBooleanFlat(selectedFilters, "hilo")
  const onestowatch = getBooleanFlat(selectedFilters, "onestowatch")
  const previousApps = getBooleanFlat(selectedFilters, "previousApps")

  const adjustmentRequired = getBooleanFlat(
    selectedFilters,
    "adjustmentRequired"
  )

  const extenuating = getBooleanFlat(selectedFilters, "extenuating")

  const convictions = getBooleanFlat(selectedFilters, "convictions")
  const orientation = getBooleanFlat(selectedFilters, "orientation")
  const hasInternalContact = getBooleanFlat(
    selectedFilters,
    "hasInternalContact"
  )

  const roleTypes = selectedFilters
    .filter(i => i.type === "roleType")
    .map(x => x.id)
  const roleLevels = selectedFilters
    .filter(i => i.type === "roleLevel")
    .map(x => x.id)
  const departments = selectedFilters
    .filter(i => i.type === "department")
    .map(x => x.id)
  const employmentTypes = selectedFilters
    .filter(i => i.type === "employmentType")
    .map(x => x.id)
  const recruiters = selectedFilters
    .filter(i => i.type === "recruiter")
    .map(x => x.id)

  const workPermit = selectedFilters
    .filter(i => i.type === "workPermit")
    .map(x => x.id)
  const candidateType = selectedFilters
    .filter(i => i.type === "candidateType")
    .map(x => x.id)
  const availability = selectedFilters
    .filter(i => i.type === "availability")
    .map(x => x.id)
  const rareContact = selectedFilters
    .filter(i => i.type === "rareContact")
    .map(x => x.id)[0]

  const clients = selectedFilters
    .filter(i => i.type === "client")
    .map(x => x.id)
  const lateralRoleTypes = selectedFilters
    .filter(i => i.type === "lateralRoleType")
    .map(x => x.id)
  const status = selectedFilters.filter(i => i.type === "status").map(x => x.id)
  const sector = selectedFilters.filter(i => i.type === "sector").map(x => x.id)

  const socialMobilityMetricsObj = {}

  socialMobilityMetrics.map(i => {
    socialMobilityMetricsObj[i.id] = true
    return i
  })

  let ks4GradesObj
  if (
    ks4Grade &&
    ks4Grade[0] &&
    ks4Grade[0].value &&
    ks4Grade[0].value.grade &&
    ks4Grade[0].value.amount
  ) {
    const { grade, amount } = ks4Grade[0].value

    ks4GradesObj = {
      [grade.id]: parseInt(amount.id, 10),
    }
  } else {
    ks4GradesObj = null
  }

  return cleanUpEmptyValues({
    customQuestions,
    adjustmentRequired,
    extenuating,
    deadlinePassed: deadlinePassed?.[0]?.value,
    interviewDate: interviewDate?.[0]?.value,
    groups:
      groups?.[0]?.value.length > 0
        ? groups?.[0]?.value.map(({ id }) => id)
        : null,
    workPermit,
    ks4schoolType,
    ks5schoolType,
    candidateType,
    availability,
    rareContact,
    ethnicity:
      ethnicity && ethnicity[0] && ethnicity[0].value
        ? ethnicity[0].value
        : null,
    socialMobilityNumOfFlags:
      socialMobilityNumOfFlags && socialMobilityNumOfFlags.length > 0
        ? socialMobilityNumOfFlags[0].id
        : null,
    gender,
    roleId: options.role && options.role.id,
    stageId: options.stage && options.stage.id,
    substageId: options.substage && options.substage.id,
    dateAdded: dateAdded && dateAdded[0] ? dateAdded[0].id : null,
    location:
      location && location.length > 0 && location[0].isPostCodeValid
        ? location[0]
        : null,
    degreeClass: degreeClass,
    degreeSubject: degreeSubject && degreeSubject[0] && degreeSubject[0].value,
    degreeType: degreeType && degreeType[0],
    legalInterests:
      legalInterests && legalInterests.length > 0 ? legalInterests : null,
    assessmentScore:
      assessmentScore && assessmentScore[0] && assessmentScore[0].value,
    universityDegreeAverage:
      universityDegreeAverage &&
      universityDegreeAverage[0] &&
      universityDegreeAverage[0].value,
    universityYear1Average:
      universityYear1Average &&
      universityYear1Average[0] &&
      universityYear1Average[0].value,
    universityYear2Average:
      universityYear2Average &&
      universityYear2Average[0] &&
      universityYear2Average[0].value,
    universityYear3Average:
      universityYear3Average &&
      universityYear3Average[0] &&
      universityYear3Average[0].value,
    universityYear4Average:
      universityYear4Average &&
      universityYear4Average[0] &&
      universityYear4Average[0].value,
    universityModuleContractScore:
      universityModuleContractScore &&
      universityModuleContractScore[0] &&
      universityModuleContractScore[0].value,
    universityModulePublicScore:
      universityModulePublicScore &&
      universityModulePublicScore[0] &&
      universityModulePublicScore[0].value,
    universityModuleTortScore:
      universityModuleTortScore &&
      universityModuleTortScore[0] &&
      universityModuleTortScore[0].value,
    universityGraduationYear:
      universityGraduationYear &&
      universityGraduationYear[0] &&
      universityGraduationYear[0].value,
    universityDegreePI:
      universityDegreePI &&
      universityDegreePI[0] &&
      universityDegreePI[0].value,
    universityYear1PI:
      universityYear1PI && universityYear1PI[0] && universityYear1PI[0].value,
    universityYear2PI:
      universityYear2PI && universityYear2PI[0] && universityYear2PI[0].value,
    universityYear3PI:
      universityYear3PI && universityYear3PI[0] && universityYear3PI[0].value,
    universityYear4PI:
      universityYear4PI && universityYear4PI[0] && universityYear4PI[0].value,
    universityContractPI:
      universityContractPI &&
      universityContractPI[0] &&
      universityContractPI[0].value,
    universityPublicPI:
      universityPublicPI &&
      universityPublicPI[0] &&
      universityPublicPI[0].value,
    universityTortPI:
      universityTortPI && universityTortPI[0] && universityTortPI[0].value,
    ks5SchoolPI: ks5SchoolPI && ks5SchoolPI[0] && ks5SchoolPI[0].value,
    ks4SchoolPI: ks4SchoolPI && ks4SchoolPI[0] && ks4SchoolPI[0].value,
    universityStartYear:
      universityStartYear &&
      universityStartYear[0] &&
      universityStartYear[0].value,
    ks4CompletionYear:
      ks4CompletionYear && ks4CompletionYear[0] && ks4CompletionYear[0].value,
    ks5CompletionYear:
      ks5CompletionYear && ks5CompletionYear[0] && ks5CompletionYear[0].value,
    ks4SchoolPercentileMin:
      ks4SchoolPercentile &&
      ks4SchoolPercentile[0] &&
      ks4SchoolPercentile[0].value[0],
    ks4SchoolPercentileMax:
      ks4SchoolPercentile &&
      ks4SchoolPercentile[0] &&
      ks4SchoolPercentile[0].value[1],
    ks5SchoolPercentileMin:
      ks5SchoolPercentile &&
      ks5SchoolPercentile[0] &&
      ks5SchoolPercentile[0].value[0],
    ks5SchoolPercentileMax:
      ks5SchoolPercentile &&
      ks5SchoolPercentile[0] &&
      ks5SchoolPercentile[0].value[1],
    ks5Grade: ks5Grade && ks5Grade[0] && ks5Grade[0],
    ks4Grades: ks4GradesObj,
    university:
      university && university[0] && university[0].value.length > 0
        ? university[0].value
        : null,
    allUniversities:
      university &&
      university[0] &&
      university[0].group.id === "all" &&
      university[0].value.length === 0
        ? true
        : null,
    ...socialMobilityMetricsObj,
    query,
    hilo,
    onestowatch,
    previousApps,
    convictions,
    hasInternalContact,
    subdomain: options.subdomain || getSubdomainFromUrl(),
    orientation,
    steps,
    statuses,
    incompleteProfile,
    healthConditions,
    searchTerm,
    ids: options.ids,
    unselectedIds: options.unselectedIds,
    assigneeId: assignee ? assignee.value : undefined,
    clients,
    sector,
    roleTypes,
    status,
    sources,
    permitTypes,
    lateralRoleTypes,
    roleLevels,
    departments,
    employmentTypes,
    recruiters,
    roleStartDate,
    roleEndDate,
    wamRanges,
  })
}

export const getStep = a => {
  return STEPS.find(x => parseInt(x.id) === parseInt(a))
    ? STEPS.find(x => parseInt(x.id) === parseInt(a)).value
    : ""
}

export const getStatus = a => {
  return CANDIDATE_STATUS.find(x => x.id === a)
    ? CANDIDATE_STATUS.find(x => x.id === a).value
    : ""
}

export const compareByString = (a, b) => {
  if (a.value < b.value) {
    return -1
  }
  if (a.value > b.value) {
    return 1
  }
  return 0
}

export const isCompletedYear = year =>
  year && year < getCurrentRecruitmentYear()

export const englishsize = years => {
  if (years.length === 1) {
    return `Year ${years[0]}`
  }
  if (years.length === 2) {
    return `Years ${years[0]} and ${years[1]}`
  }
  const missing = JSON.parse(JSON.stringify(years))
  const lastYear = missing.pop()
  return `Years ${missing.join(", ")} and ${lastYear}`
}

/**
 * Returns the HR version of the "work during school term" ID
 * @param ID
 * @returns {string}
 */
export const schoolTermWorkLabel = ID => {
  switch (ID) {
    case "1":
      return "16 hours a week for at least three consecutive school terms"
    case "2":
      return "16 hours a week for fewer than three consecutive terms"
    case "3":
      return "Fewer than 16 hours a week"
    case "4":
      return "No I didn't"
    default:
      return ""
  }
}

/**
 * Returns the HR version of the "work during uni term" ID
 * @param ID
 * @returns {string}
 */
export const uniTermWorkLabel = ID => {
  switch (ID) {
    case "1":
      return "16 hours a week for at least three consecutive university terms"
    case "2":
      return "16 hours a week for fewer than three consecutive university terms"
    case "3":
      return "Fewer than 16 hours a week"
    case "4":
      return "No I didn't"
    default:
      return ""
  }
}

export const MAX_NUMBER_OF_USERS_IN_GROUP = 5000

const getDefaultStage = (stages, stageId) => {
  const stage = stages.find(x => x.stage.id === stageId)
  if (stage) {
    const substage = stage.subStages.find(x => x.defaultSubStage)
    return {
      stage,
      substage,
    }
  }
}

export const getFirstStage = stages => {
  const query = getQueryParameters(window.location)
  let stage, substage
  const appliedStage = stages.find(x => x.stage.id === STAGES.APPLIED)
  switch (parseInt(query.selectedTabIndex || "-1")) {
    case 0:
      stage = appliedStage
      substage = appliedStage.subStages.find(
        x => x.codeStage === CODESTAGES.PENDING
      )
      break
    case 1:
      stage = appliedStage
      substage = appliedStage.subStages.find(
        x => x.codeStage === CODESTAGES.COMPLETED
      )
      break
    case 2:
      stage = stages.find(x => INTERVIEW_STAGES.indexOf(x.stage.id) > -1)
      if (stage) {
        substage = stage.subStages.find(x => x.codeStage === CODESTAGES.PENDING)
      }
      break
    case 3:
      stage = stages.find(x => x.stage.groupStage === OFFER_MADE_GROUP_STAGE)
      if (stage) {
        substage = stage.subStages.find(x => x.codeStage === CODESTAGES.PENDING)
      }
      break
    default:
      stage = stages[0]
      if (stage) {
        substage = stage.subStages.find(x => x.defaultSubStage)
      }
      break
  }
  if (stage) {
    return {
      stage,
      substage,
    }
  }
}

export const getDefaultRejectedStage = stages =>
  getDefaultStage(stages, STAGES.REJECTED)

export const convertToLong = date => date / 1000

export const convertDateToLong = date =>
  date ? date.getTime() / 1000 : undefined

export const convertLongToDate = date => (date ? new Date(date) : undefined)

export const converStringDateToLong = date =>
  date ? new Date(date).getTime() / 1000 : undefined

export const isCandidate = user => user?.userType === "CANDIDATE"

export const isBeforeEventEndTime = event => {
  const date = new Date(event.eventEndDate)
  return date.getTime() > new Date().getTime()
}

export const DEFAULT_DASH = " - "

export const formatDate = value =>
  value ? format(value, "d MMMM yyyy") : DEFAULT_DASH

export const formatMonthDate = value =>
  value ? format(value, "MMM yyyy") : DEFAULT_DASH

export const formatDateShort = value =>
  value ? format(value, "dd/MM/yyyy") : DEFAULT_DASH

export const formatMonthYear = (value, defaultValue) =>
  value ? format(value, "MMM yy") : defaultValue || DEFAULT_DASH

export const formatLookup = (lookup, defaultValue = DEFAULT_DASH) =>
  lookup ? lookup.value : defaultValue

export const formatField = (data, defaultValue = DEFAULT_DASH) =>
  data || defaultValue

export const formatPI = data =>
  data != null && data !== -9999
    ? printNumberWithSign(data) + "%"
    : DEFAULT_DASH

export const formatPercentage = (data, defaultOther = DEFAULT_DASH) => {
  return data !== DEFAULT_DASH &&
    data !== undefined &&
    data !== null &&
    data !== -9999
    ? data + "%"
    : defaultOther
}

export const formatSentanceCase = value =>
  value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()

export const filterModulesByYear = arr => {
  const obj = {}
  const newArr = []
  arr.forEach(i => {
    if (i.year) {
      if (obj[i.year]) {
        obj[i.year].push(i)
      } else {
        obj[i.year] = []
        obj[i.year].push(i)
      }
    }
    return i
  })

  for (let i in obj) {
    newArr.push({
      yearLabel: i,
      modules: obj[i],
    })
  }

  return newArr
}

export const SectionHasErrors = section => {
  let result = false
  Object.keys(section).forEach(key => {
    if (section[key]) result = true
  })
  return result
}

export const shouldShowItemByDefaultOnATS = (user, configuration, key) =>
  isCandidateOnATS(user) || isLateralMode()
    ? shouldNotHideItem(configuration, key)
    : shouldShowItem(configuration, key)

export const shouldShowSectionByDefaultOnATS = (user, configuration, key) =>
  isCandidateOnATS(user)
    ? shouldNotHideSection(configuration, key)
    : shouldShowSection(configuration, key)

export const showATSOnlyFieldForOrganization = (
  organization,
  configuration,
  key
) =>
  isATSOrganization(organization)
    ? shouldNotHideItem(configuration, key)
    : shouldShowItem(configuration, key)

export const showATSOnlySectionForOrganization = (
  organization,
  configuration,
  key
) =>
  isATSOrganization(organization)
    ? shouldNotHideSection(configuration, key)
    : shouldShowSection(configuration, key)

export const showUniversity = configuration =>
  !configuration ||
  !configuration.menus ||
  configuration.menus.university !== false

export const isCovidCandidate = (candidate, type) => {
  if (type === "university") {
    const university =
      candidate.universities &&
      candidate.universities.find(
        x =>
          x.universityType && x.universityType.toUpperCase() === UNDERGRADUATE
      )
    const uniCovid =
      university &&
      university.universityStartYear &&
      university.universityGraduationYear &&
      ((university.universityGraduationYear >= 2020 &&
        university.universityGraduationYear <= 2024) ||
        (university.universityStartYear === 2022 &&
          university.universityGraduationYear === 2025))
    return uniCovid
  }
  if (type === "sixth") {
    const sixthCovid =
      candidate.sixthFormSchoolCompletedYear >= 2020 &&
      candidate.sixthFormSchoolCompletedYear <= 2023
    return sixthCovid
  }
  if (type === "secondary") {
    const secondaryCovid =
      candidate.secondarySchoolCompletedYear >= 2020 &&
      candidate.secondarySchoolCompletedYear <= 2023
    return secondaryCovid
  }
}

export const getClosedConfig = (organization, key, defaultValue) => {
  const configuration = getFormConfigurationFromOrganization(organization)
  if (configuration.text && configuration.text[key])
    return configuration.text[key]
  return defaultValue
}

export const concatModules = (allModules, modules, category) => {
  if (modules && modules.length > 0)
    allModules.push({
      category,
      modules,
    })
}

export const getPhotoType = (filename = "") => {
  const p = filename.lastIndexOf(".")
  const q = filename.indexOf("?", p)
  const ext = filename.substring(p, q > 0 ? q : 100).toLowerCase()
  switch (ext) {
    case ".jpeg":
      return "image/jpeg"
    case ".png":
      return "image/png"
    case ".gif":
      return "image/gif"
    case ".pdf":
      return "application/pdf"
    case ".docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    default:
      return ""
  }
}

export const isEmpty = x =>
  x === undefined || x === null || (x.trim && x.trim().length === 0)

export const getCount = (applications, type) => {
  return (
    applications &&
    applications.filter(x => {
      const stageToUse = isSubStageCodeStage(x.subStage, REJECTED_GROUP_STAGE)
        ? x.lastSubStage || x.subStage
        : x.subStage
      return stageToUse.stage.stage.groupStage === type
    }).length
  )
}

export const formatDuration = duration => {
  return SESSION_DURATION.find(x => parseInt(x.id) === duration).label
}

// const convertGrade = (input, offset) => {
//   switch (input) {
//     case 'A*AA': return  100
//     case 'AAA': return  99
//     case 'AAB': return  98
//     case 'ABB': return  97
//     case 'BBB': return  96
//     case 'BBC': return  95
//     case 'BCC': return  94
//     case 'CCC': return  93
//     case 'CCD': return  92
//     case 'CDD': return  91
//     case 'DDD': return  90
//     case 'DDE': return  89
//     case 'DEE': return  88
//     case 'EEE': return  87
//     case 'EEF': return  86
//     case 'EFF': return  85
//     case 'EE': return  84
//     case 'E': return  83
//     case 'N/A': return  82*offset
//     case null: return  81*offset
//     default: return  80*offset
//   }
// }

//Live Roles
export const isLiveRoles = () => {
  if (window.location.pathname.startsWith("/manager/candidate/")) {
    const query = getQueryParameters(window.location)
    if (query.return) return query.return.indexOf("candidatesx") > -1
  }
  return window.location.pathname.indexOf("candidatesx") > -1
}

//Candidates
export const isCandidates = () => {
  if (window.location.pathname.startsWith("/manager/candidate/")) {
    const query = getQueryParameters(window.location)
    if (query.return) return query.return.indexOf("candidatesc") > -1
  }
  return window.location.pathname.indexOf("candidatesc") > -1
}

export const formatWorkPermit = candidate => {
  if (candidate.citizen) {
    return "UK Citizen"
  } else {
    if (candidate.permitType) {
      if (candidate.permitType.id === "3") {
        return candidate.otherPermit
      } else if (candidate.permitType.id === "4") {
        return "No permit"
      } else {
        return candidate.permitType.value
      }
    }
    return " - "
  }
}

export const formatNonLawLabel = x => {
  switch (x.id) {
    case "1":
      return "Graduate scheme"
    case "2":
      return "Internship"
    default:
      return x.label
  }
}

export const getMetricMin = (def, x) => {
  if (x?.min) return parseFloat(x.min, 10)
  if (def?.min) return parseFloat(def.min, 10)
  switch (def?.metricType) {
    case "percent":
      return 0
    default:
      return 0
  }
}

export const getMetricMax = (def, x) => {
  if (x?.max) return parseFloat(x.max, 10)
  if (def?.max) return parseFloat(def.max, 10)
  switch (def?.metricType) {
    case "percent":
      return 100
    default:
      return 5
  }
}

export const getMetricType = (def, x) => {
  if (x.metricType === "percent") return "number"
  return x.metricType || def.metricType || "text"
}

export const previewEmail = input => input && input.substring(0, 100)

export const getValuableResourceName = input => {
  switch (input) {
    case "Diversity Solutions Event":
      return "Name of event"
    case "Firm contact":
      return "Name of contact"
    case "Firm event/open day":
      return "Name of event"
    case "Firm social media":
      return "Name of platform"
    case "Legal Cheek event":
      return "Name of event"
    case "University Careers Service":
      return "Name of university"
    case "University event":
      return "Name of university event"
    case "University law fair":
      return "Name of university"
    case "University society":
      return "Name of society"
    case "Vantage event":
      return "Name of event"
    case "Other":
      return "Please specify"
    default:
      return null
  }
}

export const formatUniversity = undergraduate => {
  let name = formatLookup(
    undergraduate.university,
    undergraduate.universityOther
  )
  if (undergraduate.universityCollege) {
    name += ` (${undergraduate.universityCollege})`
  }
  return name
}

export const generateCandidateTitle = (organization, role) => {
  if (isProfileMode()) {
    //CandidX/Vantage
    return isInternalMode()
      ? `Rare Recruitment`
      : `Vantage | Connect with elite legal employers`
  }
  //All other products
  return `${organization && organization.name} | Apply`
}

export const generateHRTitle = organization => {
  if (isProfileMode()) {
    //CandidX/Vantage
    return isInternalMode()
      ? `Rare | Candid X`
      : `Vantage | Connect with elite legal employers`
  }
  //All other products
  const prefix = isExternalMode() || isLateralMode() ? `Candid ATS | ` : ""
  return `${prefix}${organization && organization.name} | HR`
}

export const generateCandidateDescription = organization => {
  if (isNonLegalClient(organization)) {
    return `Candid portal`
  } else if (isInternalMode()) {
    if (isProfileMode()) {
      //CandidX
      return "Rare candidate portal"
    } else {
      //CandidX - New Apps
      return `Apply to become a Rare candidate`
    }
  } else {
    if (isVantageMode()) {
      //Vantage
      return `Vantage connects you with legal employers. It's free. Sign up to hear directly from employers about work experience, vacation schemes,
      mini pupillages, training contracts, pupillages, insight events and more.`
    } else {
      //CRSAO/ATS
      return `Apply to ${organization && organization.name}`
    }
  }
}

export const generateHRDescription = organization => {
  if (isNonLegalClient(organization)) {
    return `Candid portal system`
  } else if (isInternalMode()) {
    if (isProfileMode()) {
      //CandidX
      return "Rare Candidate Management System"
    } else {
      //CandidX - New Apps
      return `Rare Candidate Management System`
    }
  } else {
    if (isVantageMode()) {
      return `Vantage connects you with legal employers. It's free. Sign up to hear directly from employers about work experience, vacation schemes,
      mini pupillages, training contracts, pupillages, insight events and more.`
    } else {
      //ATS/CRSAO
      if (isATSOrganization(organization)) {
        return `Rare Candidate Management System`
      } else {
        return `Rare Contextual Recruitment System`
      }
    }
  }
}

export const isBooleanTrue = input => input === true || input === "true"

export const isBooleanFalse = input => input === false || input === "false"

export const isBooleanFalseOrNull = input =>
  input === undefined || input === null || input === false || input === "false"

export const isTestStage = stage =>
  stage &&
  (stage.stage.stage.id === STAGES.AT_WATSON_GLASER_TEST ||
    stage.stage.stage.id === STAGES.AT_ONLINE_TEST)

export const isGTMTracker = () => {
  const subdomain = getSubdomainFromUrl()
  if (
    CookieHelper.load("acceptCookies") &&
    (subdomain === "dev-hoganlovells.appats.candidx.net" ||
      subdomain === "hoganlovells.app.candidats.io")
  ) {
    return {
      gtmId: "GTM-WV5MS57",
    }
  }
}

export const isFBTracker = () => {
  const subdomain = getSubdomainFromUrl()
  if (
    CookieHelper.load("acceptCookies") &&
    (subdomain === "dev-hoganlovells.appats.candidx.net" ||
      subdomain === "hoganlovells.app.candidats.io")
  ) {
    return "580841740002841"
  }
}

export const getResponse = (data, key) => {
  return (
    data.responses.find(x => x.questionId === key) &&
    data.responses.find(x => x.questionId === key).response
  )
}

export const containsCommands = link =>
  link.indexOf(LINK_PLACEHOLDER) > -1 ||
  link.includes(ASSESSMENT_LINK_PLACEHOLDER)

export const replaceLink = (link, buttonText = "LINK GOES HERE") => {
  const replacement = `<table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin-top: 22px; margin-bottom: 30px; "> <tr> <td><table border="0" cellspacing="0" cellpadding="0"> <tr> <td bgcolor="#50e3c2" style="padding: 13px 56px 13px 56px; border-radius: 24px; min-width: 134px;" align="center"><a href="#" target="_blank" style="font-size: 14px; line-height: 14px; letter-spacing: 3px; text-transform: uppercase; font-family:  Arial, ArialMT, Helvetica sans-serif; font-weight: normal; color: #ffffff; text-decoration: none; display: inline-block;">${buttonText}</a> </td> </tr> </table> </td> </tr> </table>`

  if (link.includes(LINK_PLACEHOLDER)) {
    return link.replace(LINK_PLACEHOLDER, replacement)
  }
  if (link.includes(ASSESSMENT_LINK_PLACEHOLDER)) {
    return link.replace(ASSESSMENT_LINK_PLACEHOLDER, replacement)
  }
  return link
}

export const changeEmail = x => {
  if (!isHRPath() && !isInternalMode()) {
    //it's not HR and it's not rare
    return x.replace(
      "support@candidats.io",
      isProfileMode() ? "info@vantageapp.io" : "support@candidats.io"
    )
  }
  return x
}

export const checkTextForEmail = text =>
  text ===
  "System error. Please contact your Rare account manager for support or email us at support@candidats.io."
    ? changeEmail(text)
    : text

export const sameFileType = (file1, file2) => {
  const type1 = file1 && file1.substring(file1.lastIndexOf("."))
  const type2 = file2 && file2.substring(file2.lastIndexOf("."))
  return type1 && type2 && type1.toLowerCase() === type2.toLowerCase()
}

export const replaceIndex = (input, screenDelta) => {
  const query = getQueryParameters(input)
  if (query.screenIndex) {
    query.screenIndex = parseInt(query.screenIndex) + screenDelta
  }
  return (
    "?" +
    Object.keys(query)
      .map(x => `${x}=${query[x]}`)
      .join(",")
  )
}

export const showLoginCheck = organization => {
  let showLogin = true
  if (organization) {
    const configuration = JSON.parse(organization.formConfiguration || "{}")
    showLogin = !configuration.oauth
  }
  return showLogin
}

export const isNull = x => x === null || x === undefined

export const filterUK = countries =>
  UK_COUNTRIES.concat(countries.filter(x => x.value !== "United Kingdom"))

export const isSubmittedApplication = candidate =>
  candidate && candidate.candidateStatus === "ACTIVE"

export const getDate = input => {
  try {
    let timestamp = new Date(input)
    return isNaN(timestamp) === false ? timestamp : ""
  } catch (e) {}
  return ""
}

export const getDegreeType = degreeTypeId => {
  const degreeType = DEGREE_TYPE.find(i => i.value === degreeTypeId)
  return degreeType ? degreeType.label : null
}

export const isSubStageCodeStage = (substage, groupstage, codestage) =>
  substage &&
  substage.stage.stage.groupStage === groupstage &&
  (!codestage || substage.codeStage === codestage)

export const isSubStageNotCodeStage = (substage, groupstage) =>
  substage && substage.stage.stage.groupStage !== groupstage

export const isSubStageSpecificStageId = (substage, id) =>
  substage && substage.stage.stage.id === id

export const isNotSubStageSpecificStageId = (substage, id) =>
  substage && substage.stage.stage.id !== id

export const formatMoney = number => {
  if (number) {
    let currency_codes = ["£", "$", "€"]
    let currency = currency_codes[0]

    currency_codes.forEach(currency_code => {
      if (("" + number).indexOf(currency_code) > -1) {
        number = number.replace(currency_code, "")
        currency = currency_code
      }
    })
    return currency + Number(number).toLocaleString()
  } else {
    return "-"
  }
}

export const numberOfDays = timestamp => {
  let returnString = "-"
  if (timestamp) {
    const timeStampInSeconds = Math.floor(timestamp / 1000)
    const currentTimeStamp = Math.floor(Date.now() / 1000)

    const numberOfDaysPassed = Math.floor(
      (currentTimeStamp - timeStampInSeconds) / 86400
    )

    switch (numberOfDaysPassed) {
      case -1:
      case 0:
        returnString = "Today"
        break
      case 1:
        returnString = "Yesterday"
        break
      default:
        returnString = `${numberOfDaysPassed} days ago`
        break
    }
  }
  return returnString
}

const paneMap = {
  TO_SCREEN: "applied",
  TO_REVIEW: "screening",
  TO_INTERVIEW: "interview",
  TO_OFFER: "offer",
}

const getValue = (pane, key) => (pane[key] != null ? pane[key] : 0)

const calculatePaneTotal = (role, pane) => {
  return (
    getValue(role[pane], "pending") +
    getValue(role[pane], "invited") +
    getValue(role[pane], "scheduled") +
    getValue(role[pane], "in_progress") +
    getValue(role[pane], "completed")
  )
}

export const calculateTotal = (roles, pane) => {
  const panes = roles && roles.filter(x => !!x[paneMap[pane]])
  return panes && panes.length > 0
    ? panes.reduce((previousValue, role) => {
        return previousValue + calculatePaneTotal(role, paneMap[pane])
      }, 0)
    : "-"
}

export const getFieldType = (field, row, options = {}) => {
  switch (field.type) {
    case "secondaryl":
      return row[field.id]?.value != null && row[field.id]?.value !== undefined
        ? row[field.id].value
        : row[field.secondary] || " - "
    case "currencyl":
      const tokens = field.id.split(".")
      return formatMoney(row[tokens[0]] && row[tokens[0]][tokens[1]]) || " - "
    case "lookupl":
      const tokens1 = field.id.split(".")
      if (tokens1.length === 2) {
        return (
          (row[tokens1[0]] && row[tokens1[0]][tokens1[1]]) ||
          field.defaultValue ||
          " - "
        )
      } else if (tokens1.length === 3) {
        return (
          (row[tokens1[0]] &&
            row[tokens1[0]][tokens1[1]] &&
            row[tokens1[0]][tokens1[1]][tokens1[2]]) ||
          field.defaultValue ||
          " - "
        )
      }
      break
    case "metric":
      return formatPI(row[field.id])
    case "ethnicity":
      return formatEthnicity(options.ethnicities, row)
    case "lookup":
      return (
        (row[field.id]
          ? row[field.id].name || row[field.id].value
          : field.defaultValue) || " - "
      )
    case "date":
      return row[field.id] ? format(new Date(row[field.id]), "d/M/yyyy") : " - "
    case "dateago":
      return row[field.id]
        ? numberOfDays(new Date(row[field.id]), "d/M/yyyy")
        : " - "
    case "score":
      return row.scores &&
        row.scores.find(x => x.stage.id === field.id.substring(6))
        ? row.scores
            .find(x => x.stage.id === field.id.substring(6))
            .score?.toFixed(1)
        : " - "
    case "candidateScore":
      return row.candidateScore?.toFixed(1) || "-"
    case "feedback":
      const { interviewAction } = options
      const yesCounter =
        row.feedbacks &&
        row.feedbacks.filter(
          x =>
            x.body &&
            JSON.parse(x.body).recommendation === true &&
            JSON.parse(x.body).interviewId === interviewAction.id
        ).length
      const noCounter =
        row.feedbacks &&
        row.feedbacks.filter(
          x =>
            x.body &&
            JSON.parse(x.body).recommendation === false &&
            JSON.parse(x.body).interviewId === interviewAction.id
        ).length
      // const unCounter =
      //   i.feedbacks && i.feedbacks.filter(x => !x.body).length

      return (
        <FeedbackWrapper>
          <FeedbackContainer>
            {yesCounter > 0 ? (
              <React.Fragment>
                <FeedbackCount>{yesCounter}</FeedbackCount>
                <FeedbackIcon>
                  <YesNoIcon variant={"YES"} />
                </FeedbackIcon>
              </React.Fragment>
            ) : (
              <React.Fragment>&nbsp;</React.Fragment>
            )}
          </FeedbackContainer>

          <FeedbackContainer>
            {noCounter > 0 ? (
              <React.Fragment>
                <FeedbackCount>{noCounter}</FeedbackCount>
                <FeedbackIcon>
                  <YesNoIcon variant={"NO"} />
                </FeedbackIcon>
              </React.Fragment>
            ) : (
              <React.Fragment>&nbsp;</React.Fragment>
            )}
          </FeedbackContainer>

          {/* <FeedbackContainer>
                {unCounter > 0 ? (
                  <React.Fragment>
                    <FeedbackCount>{unCounter}</FeedbackCount>
                    <FeedbackIcon>
                      <Icon variant={"info_light_grey"} />
                    </FeedbackIcon>
                  </React.Fragment>
                ) : (
                  <React.Fragment>&nbsp;</React.Fragment>
                )}
              </FeedbackContainer> */}
        </FeedbackWrapper>
      )
    default:
      return row[field.id] || "-"
  }
}

export const formatEventTime = (start, end) => {
  return start && end && `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`
}

export const getMoney = money =>
  parseFloat(
    ["£", "$", "€"].indexOf(money.substring(0, 1)) > -1
      ? money.substring(1)
      : money
  )

export const filterSubStages = (
  stage,
  substages,
  interviews,
  roleTemplate,
  organization
) => {
  let pendingSubStage = substages.find(x => x.codeStage === CODESTAGES.PENDING)
  const interview =
    interviews &&
    pendingSubStage &&
    roleTemplate &&
    interviews.find(
      y =>
        y.stage.id === pendingSubStage.id &&
        y.role.template.id === roleTemplate.id
    )
  const assessment = getAssessmentTypes(organization, true).find(
    x => x.stages.indexOf(stage.stage.id) > -1
  )

  let filteredSubStages = substages.filter(x => {
    return (
      // no code stage
      !x.codeStage ||
      // matching interview
      (interview
        ? //check to see if it should be on the exclusion list
          !assessment ||
          assessment.excludeOnlyForInterview.indexOf(x.codeStage) === -1
        : !assessment ||
          assessment.includeOnlyForInterview.indexOf(x.codeStage) === -1)
    )
  })
  //

  //   if (assessment.substages = )

  // })
  filteredSubStages.sort((a, b) => a.orderIndex - b.orderIndex)
  return filteredSubStages
}

export const isGSMAlphabet = text => {
  var regexp = new RegExp(
    "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
  )
  return regexp.test(text)
}

const mapTime = {
  "Greenwich Mean Time": "UK, Ireland Time",
  "British Summer Time": "UK, Ireland Time",
}

export const getTimezone = () => {
  const date = new Date()
  const dateAsString = date.toString()
  const timezone = dateAsString.match(/\(([^)]+)\)$/)[1]
  return ` ${mapTime[timezone] || timezone}`
}

export const getDocumentConfiguration = data => {
  const configuration = getFormConfigurationFromCandidate(data)
  const showDocumentByDefault =
    (isLateralMode() || isCandidateOnATS(data)) &&
    (!configuration.menus || configuration.menus.documents !== false)
  const showDocumentByConfiguration =
    configuration && configuration.custom && configuration.custom.documents
  let showCV =
    showDocumentByDefault ||
    (showDocumentByConfiguration && !!configuration.custom.documents.cv)
  let showCover =
    showDocumentByDefault ||
    (showDocumentByConfiguration && !!configuration.custom.documents.cover)
  let cvRequired = showCV
  let coverRequired = showCover
  if (configuration && configuration.custom && configuration.custom.documents) {
    showCV = showCV && configuration.custom.documents.cv !== false
    showCover = showCover && configuration.custom.documents.cover !== false
    cvRequired =
      cvRequired &&
      showCV &&
      (!configuration.custom.documents.cv ||
        configuration.custom.documents.cv.required !== false)
    coverRequired =
      coverRequired &&
      showCover &&
      (!configuration.custom.documents.cover ||
        configuration.custom.documents.cover.required !== false)
  }
  return {
    showCV,
    showCover,
    cvRequired,
    coverRequired,
  }
}

export const showWritten = language => {
  return !language || language.value !== "British Sign Language"
}

export const isAppliedStage = candidate => {
  return (
    candidate &&
    candidate.subStage &&
    candidate.subStage.stage &&
    candidate.subStage.stage.stage.id === STAGES.APPLIED
  )
}

export const initPendo = (user, organization) => {
  const correctEnvironment = isStaging || isProd
  const isCorrectProduct = isCRSAOMode() || isExternalMode()
  const isCorrectUser = isVantageMode() ? !isCandidate(user) : isHRPath()
  if (correctEnvironment && isCorrectProduct && isCorrectUser) {
    window.pendo.initialize({
      visitor: {
        id: user.id,
        role: user.userType,
        creationDate: user.createdAt,
      },

      account: {
        id: `${organization.name}.${organization.productType}.${organization.id}`,
        name: organization.name,
        sector: organization.sector && organization.sector.value,
        tier: organization.tier,
        rareContact: organization.rareContact,
        subdomain: organization.subdomain,
        creationDate: organization.createdAt,
        productType: organization.productType,
      },
    })
  }
}

export const isEmptyHtml = html => {
  return (
    !html ||
    html
      .replace(/<[^>]+>/g, "")
      .replace(/&nbsp;/g, "")
      .replace(/ /g, "")
      .trim().length === 0
  )
}

export const getCustomRejectionReasons = rejectionOptionsFromConfig =>
  rejectionOptionsFromConfig.map(option => generateOption(option))

export const getSMS = count => {
  if (count <= 160) {
    return `${count}/160 | 1 message`
  }
  if (count <= 320) {
    return `${count}/320 | 2 messages`
  }
  if (count <= 480) {
    return `${count}/480 | 3 messages`
  }
}

export const isRejectedToBeInformed = (stages, stageId, subStageId) => {
  const subStage = stages
    ?.find(x => x.id === stageId)
    ?.subStages.find(x => x.id === subStageId)
  return (
    subStage &&
    subStage.stage.stage.id === STAGES.REJECTED &&
    subStage.codeStage === CODESTAGES.PENDING
  )
}

export const hasUnallowedEmailDomains = (
  organization,
  emails,
  extraDomains = []
) => {
  const oconfiguration = getFormConfigurationFromOrganization(organization)

  if (oconfiguration?.text?.allowedReviewEmailDomains && emails?.length) {
    const domains = oconfiguration.text.allowedReviewEmailDomains
      .split(",")
      .concat(extraDomains)

    return emails.filter(Boolean).some(email => {
      const domain = email.value
        .trim()
        .toLowerCase()
        .split("@")[1]
      return !domains.includes(domain)
    })
  }
  return false
}

export const getApplications = data => {
  const allApps = (data.applications || [])
    .map(app => {
      return {
        role: {
          ...app.role,
          name: formatRoleType(
            data.role && data.role.organization,
            app.role.value
          ),
        },
        appliedAt: app.appliedAt,
      }
    })
    .concat(data.submittedApplications)

  return allApps.length ? allApps : null
}

export const getValuableResourceOptions = organization => {
  const orgConfig = getFormConfigurationFromOrganization(organization)
  if (orgConfig.textArrays?.valuableResourceOptions) {
    return orgConfig.textArrays?.valuableResourceOptions.map(option =>
      generateOption(option)
    )
  }
  return isNonLegalClient(organization)
    ? VALUABLE_RESOURCES_NON_LAW
    : VALUABLE_RESOURCES
}
export const wordCountOffset = rows => {
  let IEmargin = 0
  if (isIE()) {
    IEmargin = 20
  }
  return parseInt(rows) * 24 + 93 + IEmargin
}

export const lowercaseSortByArrayProperty = (array, property) =>
  array.sort((a, b) =>
    a[property].localeCompare(b[property], "en", { sensitivity: "base" })
  )

export const formatSingleEthnicity = (
  ethnicity,
  candidate,
  includeSpecifyForOtherOnly
) => {
  //When specifiying multiple ethnicities, only Other allows you to enter an ethnicity
  //When selecting any ethnicity with Other (as a single ethnicity), then we can include specific ethnicity
  const ETHNICITY_IDS = includeSpecifyForOtherOnly
    ? [ETHNICITY_OTHER_ID]
    : MIXED_OTHER_ETHNICITY_IDS
  return (
    formatLookup(ethnicity) +
    (ethnicity &&
      (ETHNICITY_IDS.indexOf(ethnicity.id) > -1 && candidate.ethnicitySpecify
        ? ` (${candidate.ethnicitySpecify})`
        : ""))
  )
}

export const formatEthnicity = (ethnicities, candidate) => {
  let result = formatSingleEthnicity(candidate.ethnicity, candidate, false)
  let extra
  if (candidate.ethnicity?.id === MIXED_OTHER_ETHNICITY_ID) {
    extra = ethnicities
      ?.filter(
        x =>
          candidate.ethnicityMulti.split(CUSTOM_STRING_SPLITTER).indexOf(x.id) >
          -1
      )
      .map(c => formatSingleEthnicity(c, candidate, true))
      .join(", ")
  }
  return result + (extra ? ` (${extra})` : "")
}

export const getCurrentRecruitmentYear = () =>
  new Date().getFullYear() - (new Date().getMonth() < 8 ? 1 : 0)

export const convertMapToParams = query => {
  delete query.url
  delete query.token
  const result = Object.keys(query).map(key => `${key}=${query[key]}`)
  return (result.length > 0 ? "?" : "") + result.join("&")
}

export const isEthnicityQuery = () => {
  const query = getQueryParameters()
  if (query.search) {
    const hashSearch = JSON.parse(decodeURIComponent(query.search))
    return (
      hashSearch.type === VANTAGE_FIND.BAME ||
      hashSearch.type === VANTAGE_FIND.BLACK ||
      hashSearch.type === VANTAGE_FIND.ASIAN
    )
  }
}

export const getDefaultCountry = organization => {
  return isOZAPI(organization) ? OZ_DEFAULT_COUNTRY : UK_DEFAULT_COUNTRY
}

export const isPostCodeValidatableCountry = country => {
  return country?.id === UK_DEFAULT_COUNTRY.id
}

export const selectedYes = question => question && question.id === "1"

export const isValid0To100NumberFromEvent = ev =>
  !(ev?.target?.value > 100 || ev?.target?.value < 0 || isNaN(ev.target.value))

export const getBuildProduct = () => {
  let product = "vantage"
  if (isLateralMode()) {
    product = "lateral"
  }
  if (isInternalMode()) {
    product = "candid"
  }
  if (isCRSAOMode()) {
    product = "crsao"
  }
  return product
}
